import React from "react"
import { Link } from "gatsby"
import { FaWindowMaximize, FaLaptopCode, FaReact } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import useImages from "../hooks/use-images"

import {
  SecMainHead,
  CardMainHead,
  SecImageBackground,
} from "../styles/styledComponent"

const IndexPage = () => {
  const images = useImages()

  return (
    <Layout>
      <SEO title="Rishav Pandey | HomePage" />
      <div className="home-page">
        <section className="sec-type-1">
          <div className="container">
            <div className="main-wrapper">
              <div className="main-head">
                <h1>Hello, Hola, नमस्ते !</h1>
              </div>
              <div className="main-intro">
                <p>
                  I'm <span className="custom-text">Rishav Pandey</span> — a
                  Full Stack Web and Native App Developer, Competitive
                  Programmer, Data Science Enthusiast, and an Open Source
                  Contributor, who's in love with &nbsp;
                  <span className="custom-text">&lt;/&gt;</span>.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-type-2">
          <div className="container">
            <div className="main-wrapper">
              <div className="sec-heading">
                <SecMainHead>Who am I?</SecMainHead>
              </div>
              <div className="sec-desc">
                <p>
                  Hello World, I'm a Computer Programmer with an interest in Web
                  Development, Native Application Development, Competitive
                  Programming, and Data Science. I've been working as a Software
                  Developer for the last 3 Years with hands-on industrial
                  experience. Apart from my profession, I've a serious passion
                  of teaching and spreading knowledge to mankind.
                </p>
                <div className="btn-wrapper">
                  <Link to="/about" className="link-btn">
                    Let's Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SecImageBackground
          Tag="section"
          fluid={images.home_sec_bg_image.sharp.fluid}
          alt="Section Background Image"
          fadeIn={true}
        >
          <div className="img-bg">
            <div className="container">
              <div className="main-wrapper">
                <div className="sec-heading">
                  <SecMainHead>What I do</SecMainHead>
                </div>
                <div className="sec-desc">
                  <p>
                    I'm a web developer working with several tech stacks
                    including MERN, MEAN, LAMP, and also a native app developer
                    currently using React Native. My work includes creating SPA
                    websites, PWA web applications, Native applications,
                    server-side development using nodeJS, and Python with both
                    MySQL & MongoDB. Other than development, I also do
                    Competitive Programming.
                  </p>
                </div>
                <div className="grid-view md-3-col-grid">
                  <div className="cards">
                    <div className="card-icon">
                      <FaWindowMaximize className="fa-icon fa-3x" />
                    </div>
                    <div className="card-title">
                      <CardMainHead rightLarge="43%" rightSmall="41%">
                        Web App Developer
                      </CardMainHead>
                    </div>
                    <div className="card-desc">
                      <p>
                        I work with MEAN/MERN/LAMP as Full Stack Developer, it
                        includes both front-end and back-end development.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <div className="card-icon">
                      <FaReact className="fa-icon fa-3x" />
                    </div>
                    <div className="card-title">
                      <CardMainHead rightLarge="43%" rightSmall="42%">
                        Native App Developer
                      </CardMainHead>
                    </div>
                    <div className="card-desc">
                      <p>
                        I work with React Native for creating native application
                        with runs on both Android and IOS Platform.
                      </p>
                    </div>
                  </div>
                  <div className="cards">
                    <div className="card-icon">
                      <FaLaptopCode className="fa-icon fa-3x" />
                    </div>
                    <div className="card-title">
                      <CardMainHead rightLarge="43%" rightSmall="42%">
                        Software Development
                      </CardMainHead>
                    </div>
                    <div className="card-desc">
                      <p>
                        Implementation of Algorithms and Data Structure using C,
                        C++, Python, JS in projects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SecImageBackground>
      </div>
    </Layout>
  )
}

export default IndexPage
